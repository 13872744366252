<template>
<div style="direction:rtl;" class="d-sm-flex d-md-none">
    <div>
        <v-btn
        block
        v-b-toggle.add_crmcase
        style="background: green !important;color:#FFF !important;margin-top:23px;"
        >إضافة عميل</v-btn>
    </div>
    <v-card class="mx-2 mt-2" width="100%" outlined
       v-for="item in tableList" :key="item.id" right
    >
        <v-list-item tree-line>
            <v-list-item-content>
                <div class="text-overline mb-4">
                    {{ item.full_name }} - {{ item.mobile }}
                </div>
                <v-list-item-title class="text-h5 mb-1">
                    {{ item.company }}
                </v-list-item-title>
                <v-list-item-subtitle>الرقم الضريبي : {{ item.vatid }}</v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item>
        <v-row style="width:100%;margin:2px">
            <v-col cols="12" md="3" sm="6" class="pa-2">
                النشاط: {{ item._activity }}
            </v-col>
            <v-col cols="12" md="3" sm="6" class="pa-2">
                النقاط: {{ item.posnumebr }}
            </v-col>
            <v-col cols="12" md="3" sm="6" class="pa-2">
                نوع النظام: {{ item._systemtype }}
            </v-col>
            <v-col cols="12" md="3" sm="6" class="pa-2">
                توفير أجهزة: {{ item._need_computers }}
            </v-col>
            <v-col cols="12" md="3" sm="6" class="pa-2">
                اجمالي السعر: {{ item.full_price }}
            </v-col>
        </v-row>
        
        <div class="text-center pa-2" :style="`width:100%;background:`+item.bgcolor" left>
            {{ item._status }}
        </div>
        <div style="position:absolute;top:1px;left:1px;">
            <b-button class="btn btn-success" @click="edit(item,3)">تعديل</b-button>
        </div>
    </v-card>
</div>
</template>

<script>

export default { 
    props: ['tableList'],
    data() {
        return {
            
        }
    },
    created() {
        // // // console.log(this.tableList);
    },
    methods: {
        edit(id,t){
            if(t == 1)
                this.editid = id;
            else if (t==2){
                this.custom = id;
            }
            else if (t==3){
                this.$router.push({name:'view-order', params: { id: id.id }})
            }
        },
    },
}

</script>